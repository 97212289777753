@forward './legacyVariables';
@use './legacyVariables' as *;

$rem: 1rem;
$remh: 0.5 * 1rem;
$remq: 0.25 * 1rem;

$IconIndentWidth: 50px;
$navHeight: 48px;
$border: solid 1px $xpo-grey--300;
$boxShadowTop: 0 -2px 6px 0 $xpo-grey--300;
$boxShadowBottom: 0 2px 6px 0 $xpo-grey--300;
$inactiveButtonColor: #CDD2D8;