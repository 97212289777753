/*
** ==== Basic colors ====
*/

/*
** ==== XPO Brand Color Palette ====
*/

// XPO Red
$xpo-brand-red: #cc0000;
$xpo-brand-red--900: $xpo-brand-red;
$xpo-brand-red--800: #e4210e;
$xpo-brand-red--700: #f32a13;
$xpo-brand-red--600: #ff3317;
$xpo-brand-red--500: #ff391c;
$xpo-brand-red--400: #ff5e3d;
$xpo-brand-red--300: #ff7e60;
$xpo-brand-red--200: #ffa48e;
$xpo-brand-red--100: #ffc8ba;
$xpo-brand-red--50: #fce8e6;

// XPO Black
$xpo-black: #333333;
$xpo-brand-black--900: $xpo-black;
$xpo-brand-black--800: #262626;
$xpo-brand-black--700: #555555;
$xpo-brand-black--600: #7b7b7b;
$xpo-brand-black--500: #9d9d9d;
$xpo-brand-black--400: #9d9d9d;
$xpo-brand-black--300: #c4c4c4;
$xpo-brand-black--200: #d9d9d9;
$xpo-brand-black--100: #e9e9e9;
$xpo-brand-black--50: #f5f5f5;

// XPO Dark Grey
$xpo-brand-dark-grey: #414a4c;
$xpo-brand-dark-grey--900: #22282b;
$xpo-brand-dark-grey--800: $xpo-brand-dark-grey;
$xpo-brand-dark-grey--700: #62686c;
$xpo-brand-dark-grey--600: #757d81;
$xpo-brand-dark-grey--500: #a0a6aa;
$xpo-brand-dark-grey--400: #bec5c9;
$xpo-brand-dark-grey--300: #e0e7eb;
$xpo-brand-dark-grey--200: #ebf2f6;
$xpo-brand-dark-grey--100: #f0f7fb;
$xpo-brand-dark-grey--50: #f4fcff;

// XPO Medium Grey
$xpo-brand-medium-grey: #848484;
$xpo-brand-medium-grey--900: #2e2e2e;
$xpo-brand-medium-grey--800: #505050;
$xpo-brand-medium-grey--700: #707070;
$xpo-brand-medium-grey--600: $xpo-brand-medium-grey;
$xpo-brand-medium-grey--500: #afafaf;
$xpo-brand-medium-grey--400: #cdcdcd;
$xpo-brand-medium-grey--300: #eeeeee;
$xpo-brand-medium-grey--200: #f3f3f3;
$xpo-brand-medium-grey--100: #f8f8f8;
$xpo-brand-medium-grey--50: #fcfcfc;

// XPO Light Grey
$xpo-brand-light-grey: #dedede;
$xpo-brand-light-grey--900: #1f1f1f;
$xpo-brand-light-grey--800: #3f3f3f;
$xpo-brand-light-grey--700: #5e5e5e;
$xpo-brand-light-grey--600: #727272;
$xpo-brand-light-grey--500: #9a9a9a;
$xpo-brand-light-grey--400: #bababa;
$xpo-brand-light-grey--300: $xpo-brand-light-grey;
$xpo-brand-light-grey--200: #ececec;
$xpo-brand-light-grey--100: #f4f4f4;
$xpo-brand-light-grey--50: #f9f9f9;

/*
** ==== Main Color Palette ====
*/

// Black and Grey
$xpo-grey--975: #212121;
$xpo-grey--950: #333333;
$xpo-grey--900: #4a4a4a;
$xpo-grey--700: #6f6f6f;
$xpo-grey--350: #bdbdbd;
$xpo-grey--300: #d8d8d8;
$xpo-grey--80: #f6f6f6;
$xpo-grey--25: #fafafa;

$xpo-white: #ffffff;

// Links, Buttons, Tabset and Tabpills
$xpo-blue--850: #0d47a1;
$xpo-blue--800: #1976d2;
$xpo-blue--350: #2196f3;

// Error State
$xpo-red--200: #b71c1c;
$xpo-red--150: #d32f2f;
$xpo-red--50: #fceeeb;

// Success State
$xpo-green--600: #388e3c;
$xpo-green--450: #4caf50;
$xpo-green--50: #f1f9f1;

// Warning State
$xpo-yellow--350: #ffd600;

$xpo-orange--350: #ff6d00;

// Info State
$xpo-blue--700: #0091ea;

// Transactional Cells
$xpo-grey--150: #eceff1;

$xpo-blueGrey--700: #cfd8dc;

// Accent Colors
$xpo-pink--700: #c51162;

$xpo-teal--700: #00bfa5;

$xpo-blue--825: #304ffe;
$xpo-blue--200: #e4effa;

$xpo-accent-red--400: $xpo-brand-red--400;
$xpo-accent-red--100: $xpo-brand-red--100;

$xpo-purple--700: #6200ea;

$xpo-green--200: #a4e59b;

$xpo-yellow--200: #fff9c4;

$xpo-blue--900: #0b477c;

// ACTION COLORS

/*
** ==== DEPRECATED COLORS ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$xpo-blue--100: #e8f5fe;
// Deprecated
$xpo-blue--150: #e3f2fd;
// Deprecated
$xpo-blue--300: #0d94f4;
// Deprecated
$xpo-blue--400: #0868ac;
// Deprecated
$xpo-blue--900: #053c63;
// Deprecated
$xpo-yellow--100: #fff8e5;
// Deprecated
$xpo-yellow--300: #ffcf4d;
// Deprecated
$xpo-yellow--400: #ffba00;
// Deprecated
$xpo-yellow--700: #ffd600;
// Deprecated
$xpo-yellow--900: #4d3800;
// Deprecated
$xpo-green--100: #c8ffdf;
// Deprecated
$xpo-green--300: #1ce855;
// Deprecated
$xpo-green--350: #00c853;
// Deprecated
$xpo-green--400: #11a63b;
// Deprecated
$xpo-green--700: #00c853;
// Deprecated
$xpo-green--900: #0a6122;
// Deprecated
$xpo-grey--50: #fbfbfb;
// Deprecated
$xpo-grey--60: #fafafa;
// Deprecated
$xpo-grey--100: #f3f3f3;
// Deprecated
$xpo-grey--200: #e6e6e6;
// Deprecated
$xpo-grey--250: #dddddd;
// Deprecated
$xpo-grey--400: #9b9b9b;
// Deprecated
$xpo-grey--600: #757575;
// Deprecated
$xpo-grey--650: #858585;
// Deprecated
$xpo-grey--750: #575757;
// Deprecated
$xpo-grey--915: #43494d;
// Deprecated
$xpo-grey--930: #424242;
// Deprecated
$xpo-grey--1000: #171717;
// Deprecated
$xpo-grey--1100: #212121;
// Deprecated
$xpo-red--100: #ffeeee;
// Deprecated
$xpo-red--250: #dd2c00;
// Deprecated
$xpo-red--300: #ff2222;
// Deprecated
$xpo-red--400: #d50000;
// Deprecated
$xpo-red--600: #cc0000;
// Deprecated
$xpo-red--700: #d32f2f;
// Deprecated
$xpo-red--900: #550000;
// Deprecated
$xpo-orange--100: #ffdfca;
// Deprecated
$xpo-orange--300: #ff9957;
// Deprecated
$xpo-orange--400: #ff6400;
// Deprecated
$xpo-orange--900: #8b3700;
// Deprecated
$xpo-fontColor--link: #0868ac;
// Deprecated
$xpo-backgroundColor--body: #f3f3f3;
// Deprecated
$xpo-color--error: #d50000;
// Deprecated
$xpo-color--success: #11a63b;
// Deprecated
$xpo-color--warn: #ffba00;
// Deprecated
$xpo-color--info: #4a4a4a;

/*
** ==== COLORS APPLIED ====
*/
$xpo-fontColor--primary: $xpo-grey--900;

/*
** ==== Typography ====
*/
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--xSmall: 0.714rem; //10px // TODO: seems to small for external, might be hard to see
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.285rem; //18px
$xpo-fontSize--xxlarge: 1.714rem; //24px

$xpo-header-fontSize--xsmall: 1rem; //14px
$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px
$xpo-header-fontSize--xlarge: 1.571rem; //22px
$xpo-header-fontSize--xxlarge: 1.857rem; //26px

$xpo-fontFamily--primary: 'Inter', Arial, sans-serif;

$xpo-fontWeight--normal: 400;
$xpo-fontWeight--regular: 500;
$xpo-fontWeight--bold: 700;
$xpo-fontWeight--extraBold: 800;
$xpo-fontWeight--extraLargeBold: 900;

$xpo-lineHeight--xl: 2.286rem; // 32px
$xpo-lineHeight--lg: 1.714rem; // 24px
$xpo-lineHeight--md: 1.571rem; // 22px
$xpo-lineHeight--sm: 1.429rem; // 20px

$xpo-lineHeight--base: 24px; // for medium and large font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-lineHeight--small: 16px; // for small font size

$xpo-header-lineHeight--small: 1.143; // DEPRECATED
$xpo-header-lineHeight--medium: 1.143; // DEPRECATED
$xpo-header-lineHeight--large: 1.286; // DEPRECATED

$xpo-letterSpacing--base: 0.2px;
$xpo-header-letterSpacing--small: 0.3px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: $xpo-blue--200;

// -- NGX-UI Specific --
$ngx-footer-height: 42px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 30px;

$ngx-icon-size: 24px; // TODO: Deprecate this variable
$xpo-icon-size--standard: 20px;
$xpo-icon-size--small: 18px;
$xpo-icon-size--large: 24px;

$xpo-input-height--default: 30px;
$xpo-input-height--twoLines: 45px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-brand-medium-grey--200;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-brand-medium-grey--200;

$ngx-menu-backgroundColor: $xpo-white;

/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/

/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
// Deprecated
$ngx-Snackbar-status--success: $xpo-green--450;

// Deprecated
$ngx-Snackbar-status--error: $xpo-red--150;

// Deprecated
$ngx-Snackbar-status--info: $xpo-blue--200;

// Deprecated
$ngx-Snackbar-status--warn: #fbc02d;

// Deprecated
$ngx-Snackbar-dismiss--success: $xpo-green--600;
$ngx-Snackbar-dismiss--error: $xpo-red--200;

// Deprecated
$ngx-Snackbar-dismiss--info: #01579b;

// Deprecated
$ngx-SnackBar-marginTop: 32px;

//Badge
$_matBadgeContent-backgroundColor--negative: $xpo-red--50;
$_matBadgeContent-color--negative: #dd2c00;
$_matBadgeContent-backgroundColor--positive: $xpo-green--50;

$ngx-menu-backgroundColor-onHover: $xpo-brand-medium-grey--50;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// --- Transitions ---
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;

// --- Web Variables ---
$ltlc-form-maxWidth--medium: 450px;
$ltlc-form-maxWidth--standard: 600px;
$ltlc-form-maxWidth--large: 936px;
$ltlc-form-maxWidth--xLarge: 1424px;
$xpo-filter-margin : 4px;
$xpo-border--small:0.5px;
$xpo-border-radius-toggle--small:6px;
$xpo-status-height:70%;
$xpo-status-width:75px;
$xpo-height:500px;
// ---Board Height---
$xpo-board-height:350px;
$xpo-filter-field-height: 34px;
