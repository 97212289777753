@use './../partials/variables' as *;
@use './../partials/mixins' as *;

.exDev-Utils {
    &-grid {
        display: grid;
        column-gap: $rem;
        height: fit-content;
        &-templateColumn2 {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include media-screen-range-0_sm {
            display: block;
            grid-template-columns: 1fr !important;
            column-gap: unset;
      
            &-item {
              grid-column: 1 !important;
              max-width: 100% !important;
            }
          }
    }
    
}