@use 'variables' as *;
@use 'utils' as *;
@use 'skeleton' as *;

html, body {
  margin: 0;
  overscroll-behavior-y: contain; //Avoids page refresh when pulling page down
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  background: $xpo-white;
}

h1,h2,h3,h4,h5,h6 {
  text-wrap:balance;
  font-weight: 400;
  margin: 0;
}

a {
  text-decoration-color: $xpo-blue--800;
  color: $xpo-blue--800;
  cursor: pointer;
  text-decoration: none;
}

.xpo-Select-panel.mat-select-panel {
  background-color: $xpo-grey--25;
}

.multi-line-snackbar { white-space: pre-line; }

.override-padding{
  .mat-dialog-container.mat-dialog-container {
    padding: 0 !important;
  }
}

.xpo-LtlMenuHeader .mdc-tab .mdc-tab__text-label{
  font-family: unset !important;
}

.xpoLtl-SecondaryLinks .mat-tab-link{
  font-family: unset !important;
}
