@use './../../partials/variables' as *;


@mixin cbase-snackbar {
  .xpo-snackbar {
    &-success {
      @include alert-snackbar($ngx-Snackbar-status--success);
    }
    &-warn {
      @include alert-snackbar($ngx-Snackbar-status--warn);
      color: $xpo-grey--950;
    }
    &-info {
      @include alert-snackbar($ngx-Snackbar-status--info);
    }
    &-error {
      @include alert-snackbar($ngx-Snackbar-status--error);
    }
  }
  
}


@mixin alert-snackbar($backgroundColor){
  white-space: pre-wrap;
  background-color:$backgroundColor;

  &.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      background-color:$backgroundColor;
    }
  }
  .mdc-button .mdc-button__label {
    font-family: 'Material Icons';
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    color: $xpo-white;
  }
  button[matsnackbaraction] {
    margin: $remh 0 auto auto;
  }
  .mdc-snackbar__surface {
    padding-right: 0;
  }
}