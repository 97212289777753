/*
** ==== Basic colors ====
*/
/*
** ==== XPO Brand Color Palette ====
*/
/*
** ==== Main Color Palette ====
*/
/*
** ==== DEPRECATED COLORS ====
*/
/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
/*
** ==== COLORS APPLIED ====
*/
/*
** ==== Typography ====
*/
/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/
/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
.flex--stretch {
  flex: 1;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter-Regular.woff2") format("woff2");
  display: swap;
  font-weight: 100 400;
}
@font-face {
  font-family: "Inter";
  src: url("./Inter-Bold.woff2") format("woff2");
  display: swap;
  font-weight: 500 800;
}
@font-face {
  font-family: "Inter";
  src: url("./Inter-ExtraBold.woff2") format("woff2");
  display: swap;
  font-weight: 800 900;
}
.mat-mdc-button-base.mat-primary .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-button-base.mat-primary .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--mdc-protected-button-label-text-color, inherit);
}

.mdc-button, .mdc-button--unelevated, .mat-mdc-outlined-button, .mat-stroked-button {
  text-transform: uppercase;
}

.mat-primary.mat-mdc-outlined-button:not(:disabled) {
  --mdc-outlined-button-outline-color: var(--mdc-outlined-button-label-text-color);
}

.mat-mdc-form-field, .mat-form-field {
  width: 100%;
}

mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface {
  padding: 32px 32px 0;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mdc-dialog__title {
  color: #333333;
  font-weight: 700;
  line-height: 28px;
  font-size: 1.714rem;
  padding: 0 0 16px;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-content {
  color: #4a4a4a;
  font-size: 1.285rem;
  line-height: 1.714rem;
  padding: 0 0 32px 0;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions {
  border-top: 1px solid #d8d8d8;
  padding: 32px 0;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base {
  height: 56px;
  font-size: 1.428rem;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button, mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button--unelevated, mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base .mat-mdc-outlined-button {
  padding: 1rem !important;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base .mat-mdc-button.mat-mdc-button-base, mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base .mat-mdc-raised-button.mat-mdc-button-base, mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base .mat-mdc-unelevated-button.mat-mdc-button-base, mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions .mat-mdc-button-base .mat-mdc-outlined-button.mat-mdc-button-base {
  height: unset;
}

.xpo-snackbar-success {
  white-space: pre-wrap;
  background-color: #4caf50;
}
.xpo-snackbar-success.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #4caf50;
}
.xpo-snackbar-success .mdc-button .mdc-button__label {
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  color: #ffffff;
}
.xpo-snackbar-success button[matsnackbaraction] {
  margin: 0.5rem 0 auto auto;
}
.xpo-snackbar-success .mdc-snackbar__surface {
  padding-right: 0;
}
.xpo-snackbar-warn {
  white-space: pre-wrap;
  background-color: #fbc02d;
  color: #333333;
}
.xpo-snackbar-warn.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #fbc02d;
}
.xpo-snackbar-warn .mdc-button .mdc-button__label {
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  color: #ffffff;
}
.xpo-snackbar-warn button[matsnackbaraction] {
  margin: 0.5rem 0 auto auto;
}
.xpo-snackbar-warn .mdc-snackbar__surface {
  padding-right: 0;
}
.xpo-snackbar-info {
  white-space: pre-wrap;
  background-color: #e4effa;
}
.xpo-snackbar-info.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #e4effa;
}
.xpo-snackbar-info .mdc-button .mdc-button__label {
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  color: #ffffff;
}
.xpo-snackbar-info button[matsnackbaraction] {
  margin: 0.5rem 0 auto auto;
}
.xpo-snackbar-info .mdc-snackbar__surface {
  padding-right: 0;
}
.xpo-snackbar-error {
  white-space: pre-wrap;
  background-color: #d32f2f;
}
.xpo-snackbar-error.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #d32f2f;
}
.xpo-snackbar-error .mdc-button .mdc-button__label {
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  color: #ffffff;
}
.xpo-snackbar-error button[matsnackbaraction] {
  margin: 0.5rem 0 auto auto;
}
.xpo-snackbar-error .mdc-snackbar__surface {
  padding-right: 0;
}

.mat-menu-panel {
  min-height: unset !important;
  padding: 8px 0;
}