@use './../../partials/variables' as *;

@mixin overrideInternalFontFamily {
    .xpo-AgGrid, .mat-checkbox,
    .mat-button, .mat-raised-button,
    .mat-icon-button, .mat-stroked-button,
    .mat-flat-button, .mat-fab,
    .mat-mini-fab,
    mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-input-element,
    mat-form-field.mat-form-field-type-mat-input .mat-form-field-label {
        font-family: $xpo-fontFamily--primary;
    }
}