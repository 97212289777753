@use './../partials/variables' as *;
@use './../partials/mixins' as *;

.exDev-Utils-skeleton {
  $skeleton-text-background: $xpo-grey--300;
  $skeleton-text-background-animated: $xpo-grey--80;

  &-img {
    filter: blur(5px);
  }

  &-animated {
    min-height: $xpo-contentSpacing;
    position: relative;
    display: inline-block;
    background: linear-gradient(
      to right,
      $skeleton-text-background 8%,
      $skeleton-text-background-animated 18%,
      $skeleton-text-background 33%
    );
    background-size: 800px 104px;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
  }

  @keyframes shimmer {
    0% {
      background-position: -400px 0;
    }

    100% {
      background-position: 400px 0;
    }
  }
}
 