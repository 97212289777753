//TODO: workaround to align the header filter dropdown checkboxes
@mixin overrideSelectPanelFilter {
    xpo-advanced-select-panel {
        xpo-advanced-select-tree-node,
        mat-checkbox {
        margin-left: 0px !important;
        .mat-checkbox-inner-container {
            margin-top: 7px !important ;
        }
        }
    }
}