@use './../../partials/variables' as *;
@use './mat-button.scss' as *;

@mixin cbase-dialog {
mat-dialog-container.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface{
  padding: 2*$xpo-contentSpacing 2*$xpo-contentSpacing 0;
  .mdc-dialog__title {
    $_height: $xpo-lineHeight--base + 4;

    color: $xpo-black;
    font-weight: $xpo-fontWeight--bold;
    line-height: $_height;
    font-size: $xpo-fontSize--xxlarge;
    padding: 0 0 $xpo-contentSpacing;
  }

  .mat-mdc-dialog-content {
    color: $xpo-grey--900;
    font-size: $xpo-fontSize--extraLarge;
    line-height: $xpo-lineHeight--lg;
    padding: 0 0 $xpo-contentSpacing * 2 0;
  }

  .mat-mdc-dialog-actions {
    border-top: 1px solid $xpo-grey--300;
    padding: $xpo-contentSpacing * 2 0;

    .mat-mdc-button-base {
      height:56px;
      font-size: $xpo-header-fontSize--large;
      @include cbase-formButton;
    }
  }
}
}
}