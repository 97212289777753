@use '@angular/material' as mat;
@use 'node_modules/@angular/material/theming'  as matt;
@use './cbase-variables.scss' as *;
@use './../../partials/variables' as *;

@mixin cbase-buttons {
    .mat-mdc-button-base.mat-primary {
        .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
        .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
            stroke: var(--mdc-protected-button-label-text-color, inherit);
        }
    }

    .mdc-button, .mdc-button--unelevated,  .mat-mdc-outlined-button, .mat-stroked-button {
        text-transform: uppercase;
    }

    .mat-primary.mat-mdc-outlined-button:not(:disabled) {
        --mdc-outlined-button-outline-color: var(--mdc-outlined-button-label-text-color);
    }
}


@mixin cbase-formButton {
    .mdc-button, .mdc-button--unelevated,  .mat-mdc-outlined-button {
        padding: $rem !important;
    }
    .mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
        height: unset;
    }
}

@mixin cbase-gridButton {
    .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 0;
        height: unset;
        width: unset;
    }
    .mdc-icon-button {
        display: flex;
    }
}